import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './assets/styles/main.scss'
import registerGlobalComponents from '@/components/_global'
import VueGtag from 'vue-gtag'

const app = createApp(App)
registerGlobalComponents(app)

app.use(VueGtag, {
  config: {
    id: 'G-0V2QS28W69'
  }
}, router)

app.use(store)
app.use(router)
app.mount('#app')
