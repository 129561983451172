import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'Default',
    component: () => import('../views/default.vue')
  },
  {
    path: '/donate',
    name: 'donate-page',
    component: () => import('../views/donate.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior () {
    return { top: 0 }
  }
})

export default router
