<template>
  <div class="spinner">
    <span></span>
    <span></span>
    <span></span>
  </div>
</template>

<style lang="scss" scoped>
@keyframes s1 {
  0% {
    background-color: var(--color-grey-light-4);
  }
}

@keyframes s2 {
  33% {
    background-color: var(--color-grey-light-4);
  }
}

@keyframes s3 {
  66% {
    background-color: var(--color-grey-light-4);
  }
}

.spinner {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 5rem;
  height: 100%;
  transition-duration: 250ms;
  transform: translate3d(0, 2.3rem, 0);

  span {
    width: 6px;
    height: 70%;

    border-radius: 6px;
    background-color: var(--color-grey);

    transition: background-color 250ms ease-in;
    animation: s1 1s linear infinite;

    &:nth-of-type(2) {
      height: 100%;
      animation-name: s2;
    }

    &:nth-of-type(3) {
      animation-name: s3;
    }
  }

  position: relative;
}
</style>
