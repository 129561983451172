<template>
  <svg class="gc-icon">
    <use :xlink:href="require(`@/assets/icons/${sprite}.svg`) + `#icon-${icon}`"/>
  </svg>
</template>

<script>
export default {
  name: 'gc-icon',
  props: {
    icon: {
      type: String,
      default: 'forward'
    },
    sprite: {
      type: String,
      default: 'vicons'
    }
  }
}
</script>

<style lang="scss" scoped>
  svg {
    height: 100%;
    width: 100%;
  }
</style>
