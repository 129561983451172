<script>
export default {
  inheritAttrs: false,
  props: {
    title: { type: String, default: '' },
    subtitle: { type: String, default: '' },
    modelValue: {
      type: Boolean,
      default: false
    },
    centered: { type: Boolean, default: false },
    styles: { type: Object, default: () => {} }
  },
  methods: {
    closeModalHandler () {
      this.$emit('close')
      this.$emit('update:modelValue', false)
    }
  },
  emits: ['close']
}
</script>

<template>
  <section
    class="bp-modal"
    :class="{ 'bp-modal__show': modelValue }"
    v-bind="$attrs"
  >
    <div
      class="bp-modal__content"
      :class="{ 'bp-modal__content--centered': centered }"
      :style="styles"
    >
      <header class="bp-modal__head">
        <div class="bp-modal__head-title">
          <h2 class="bp-modal__main-title">{{ title }}</h2>
          <p class="bp-modal__sub-title">{{ subtitle }}</p>
        </div>
        <div
          @click="closeModalHandler"
          class="bp-modal__head-close bp-u-center-content"
        >
          &times;
        </div>
      </header>
      <slot></slot>
    </div>
  </section>
</template>

<style scoped>
.bp-modal {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100;
  background: linear-gradient(
    35.63deg,
    rgba(22, 29, 111, 0.5) 1.32%,
    rgba(132, 139, 221, 0.4) 99.72%
  );
  mix-blend-mode: normal;
  backdrop-filter: blur(10px);
  transition: visibility 0.2s, opacity 0.4s linear;
  visibility: hidden;
  opacity: 0;
}

.bp-modal__show {
  visibility: visible;
  opacity: 1;
  overflow-y: auto;
}

.bp-modal__content {
  position: relative;
  overflow: auto;
  width: 50rem;
  max-width: 90%;
  margin: 40px auto;
  border-radius: 5px;
  background-color: white;
  display: flex;
  flex-direction: column;
}

.bp-modal__content--centered {
  margin-top: 15%;
}

.bp-modal__head {
  height: 5rem;
  display: grid;
  grid-template-columns: auto 5rem;
  grid-gap: 5rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  align-items: center;
  padding: 0 2rem;
}

.bp-modal__main-title {
  font-size: 2.2rem;
  font-weight: 100;
  color: var(--color-text);
}

.bp-modal__sub-title {
  line-height: 1.4;
  font-size: 1.5rem;
  color: var(--color-primary-light-1);
}

.bp-modal__head-close {
  margin-left: 2rem;
  width: 3rem;
  cursor: pointer;
  border-radius: 5px;
  font-size: 3rem;
  text-align: center;
}
</style>
